<template>
  <b-row class="justify-content-center">
    <b-card>
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <b-row>
          <b-col md="12">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group
                label-for="languages"
              >
                <label class="selectLanguage"> Plese Select the Language You want to show data </label>
                <v-select
                  id="languages"
                  v-model="code"
                  label="name"
                  required
                  :reduce="lang => lang.code"
                  :options="languagesList"
                  class="select-size-lg"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="code">
          <b-col md="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Page Head Title"
                label-for="title_head"
              >
                <b-form-input
                  id="title_head"
                  v-model="editAboutPageData.page_head_title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Page Head Title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="About Page Description"
                label-for="desc"
              >
                <b-form-textarea
                  id="desc"
                  v-model="editAboutPageData.page_head_desc"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="About Page Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Page Head Image"
            >
              <b-form-file
                accept=".jpg, .png, .gif"
                placeholder="Choose file"
                multiple
                @change="onChangePageHeadImage"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <div
              v-if="preview"
              class="mt-2 shadow handle"
            >
              <img
                :src="preview"
                class="img-fluid"
                width="100"
              >
            </div>
            <div
              v-else
              class="mt-2 shadow handle"
            >
              <img
                :src="editAboutPageData.page_head_image"
                class="img-fluid"
                width="100"
              >
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Page Descripption"
              label-for="blog-content"
              class="mb-2"
            >
              <quill-editor
                id="blog-content"
                v-model="editAboutPageData.page_des"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Notified Title"
                label-for="desc"
              >
                <b-form-input
                  id="desc"
                  v-model="editAboutPageData.notifidByTitle"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="About Page Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Notified Image"
            >
              <VueFileAgent
                ref="vueFileAgent"
                v-model="fileRecordsNote"
                :theme="'grid'"
                :multiple="true"
                :deletable="true"
                :meta="true"
                :accept="'image/*'"
                :max-size="'10MB'"
                :max-files="14"
                :help-text="'Choose images'"
                :error-text="{
                  type: 'Invalid file type. Only images Allowed',
                }"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDeleteNote($event)"
                @delete="fileDeletedNote($event)"
              />
              <b-row
                v-if="code"
                :class="{ 'dashed': imagesNote.length > 0 }"
              >
                <b-col
                  v-for="(img, idx) in imagesNote"
                  :key="idx"
                  class="py-3"
                  md="2"
                >
                  <div class="img-wrap">
                    <img
                      :src="img.image"
                      width="200"
                      class="px-5"
                    >
                    <feather-icon
                      icon="DeleteIcon"
                      class="delete-img"
                      size="20"
                      @click="deleteImageNote(img.id)"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Key & Values File"
            >
              <b-form-file
                accept=".jpg, .png, .pdf"
                placeholder="Choose file"
                multiple
                @change="onChangeFile"
              />
              <div
                v-if="previewKey"
                class="mt-2 shadow handle"
              >
                <img
                  :src="previewKey"
                  class="img-fluid"
                  width="100"
                >
              </div>
              <div
                v-else
                class="mt-2 shadow handle"
              >
                <img
                  :src="editAboutPageData.key_values_file_file"
                  class="img-fluid"
                  width="100"
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="key & values File Title"
                label-for="key_tit"
              >
                <b-form-input
                  id="key_tit"
                  v-model="editAboutPageData.key_values_file_title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="About Page Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Why Us Title"
                label-for="key_tit"
              >
                <b-form-input
                  id="key_tit"
                  v-model="editAboutPageData.why_us_title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Why Us Title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Why Us Description"
              label-for="blog-content"
              class="mb-2"
            >
              <quill-editor
                id="blog-content"
                v-model="editAboutPageData.why_us_desc"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-for="(item, idx) in editAboutPageData.main_key_values"
          v-show="code"
          :key="idx"
        >
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Key Values Title"
                label-for="key_tit"
              >
                <b-form-input
                  id="key_tit"
                  v-model="item.title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Key Values Title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Key Values Description"
                label-for="key_tit"
              >
                <b-form-textarea
                  id="key_tit"
                  v-model="item.description"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Key Values Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row
          v-if="code"
          class="mb-3"
        >
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="addKeyValue"
            >
              Add New Key Value
            </b-button>
          </b-col> <br>
        </b-row>
        <b-row v-if="code">
          <b-col md="6">
            <VueFileAgent
              ref="vueFileAgent"
              v-model="fileRecords"
              :theme="'grid'"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/*'"
              :max-size="'10MB'"
              :max-files="14"
              :help-text="'Choose images'"
              :error-text="{
                type: 'Invalid file type. Only images Allowed',
              }"
              @select="filesSelected($event)"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="code"
          :class="{ 'dashed': images.length > 0 }"
        >
          <b-col
            v-for="(img, idx) in images"
            :key="idx"
            class="py-3"
            md="2"
          >
            <div class="img-wrap">
              <img
                :src="img.image"
                width="200"
                class="px-5"
              >
              <feather-icon
                icon="DeleteIcon"
                class="delete-img"
                size="20"
                @click="deleteImage(img.id)"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="code">
          <b-col md="6">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group
                label-for="languages"
              >
                <label class="selectLanguage"> Plese Select The language in which you want to add this information </label>
                <v-select
                  id="languages"
                  v-model="editAboutPageData.language_id"
                  label="name"
                  required
                  :reduce="lang => lang.id"
                  :options="languagesList"
                  class="select-size-lg"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-col
          v-if="code"
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="addAboutPAgeData()"
          >
            Save
          </b-button>
        </b-col>
      </validation-observer>
    </b-card>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */

import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import store from '@/store'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'
import about from './about'
import aboutModule from './aboutModule'

Vue.use(VueSweetalert2)
Vue.use(VueFileAgent)

export default {
  components: {
    BCard,
    vSelect,
    selectLanguage,
    BMediaAside,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
    quillEditor,
    VueFileAgentStyles,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      preview: null,
      image: null,
      previewNotified: null,
      imageNotified: null,
      previewKey: null,
      imageKey: null,
      fileRecords: [],
      fileRecordsForUpload: [],
      fileRecordsNote: [],
      fileRecordsForUploadNote: [],
      previewImages: [],
      languagesList: [],
      languageCode: '',
    }
  },
  setup() {
    const HEADERS_MODULE = 'headersModule'

    // Register module
    if (!store.hasModule(HEADERS_MODULE)) store.registerModule(HEADERS_MODULE, aboutModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADERS_MODULE)) store.unregisterModule(HEADERS_MODULE)
    })

    const {
      editAboutPageData, getHead, code, images, imagesNote,
    } = about()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      editAboutPageData,
      refFormObserver,
      getValidationState,
      resetForm,
      imagesNote,
      getHead,
      code,
      images,
    }
  },
  computed: {
    // previewImagesData() {
    //   return this.previewImages = this.editAboutPageData.clients
    // },
  },
  created() {
    // this.getAboutPageData()
    this.getLanguages()
    this.previewImagesData()
  },
  methods: {
    async getLanguages() {
      const response = await axios.get('get/language')
      this.languagesList = response.data.success
    },
    onChangePageHeadImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editAboutPageData.page_head_image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeFile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editAboutPageData.key_values_file_file = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.previewKey = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.imageKey = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangePageNotifiedImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editAboutPageData.notifidByImages = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.previewNotified = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.imageNotified = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    addKeyValue() {
      this.editAboutPageData.main_key_values.push({
        title: '',
        description: '',
      })
    },
    previewImagesData() {
      this.previewImages = this.editAboutPageData.clients
      console.log(this.images)
    },
    // getAboutPageData() {
    //   axios.get(`get/about_us?language_code=${this.languageCode}`).then(res => {
    //     // eslint-disable-next-line prefer-destructuring
    //     this.editAboutPageData = res.data.success
    //     this.previewImages = res.data.success.clients
    //   })
    // },
    addAboutPAgeData() {
      const formData = new FormData()
      formData.append('page_head_title', this.editAboutPageData.page_head_title)
      formData.append('page_head_desc', this.editAboutPageData.page_head_desc)
      formData.append('page_head_image', this.editAboutPageData.page_head_image)
      formData.append('page_des', this.editAboutPageData.page_des)
      formData.append('notifidByTitle', this.editAboutPageData.notifidByTitle)
      formData.append('key_values_file_title', this.editAboutPageData.key_values_file_title)
      formData.append('key_values_file_file', this.editAboutPageData.key_values_file_file)
      formData.append('why_us_title', this.editAboutPageData.why_us_title)
      formData.append('why_us_desc', this.editAboutPageData.why_us_desc)
      formData.append('language_id', this.editAboutPageData.language_id)
      this.fileRecords.map(files => formData.append('clients[]', files.file))
      // formData.append('notifidByImages', this.editAboutPageData.notifidByImages)
      this.fileRecordsNote.map(files => formData.append('notifidByImages[]', files.file))
      // eslint-disable-next-line array-callback-return
      this.editAboutPageData.main_key_values.map((item, key) => {
        formData.append(`main_key_values[${key}][title]`, item.title)
        formData.append(`main_key_values[${key}][description]`, item.description)
      })

      axios.post('add/about_us', formData).then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).catch(error => {
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    deleteImage(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/about_us/clint/${id}`).then(res => {
            // eslint-disable-next-line no-unused-expressions
            this.getAboutPageData()
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    deleteImageNote(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/about_us/notifid_by_images/${id}`).then(res => {
            // eslint-disable-next-line no-unused-expressions
            this.getAboutPageData()
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error)
      const validFileRecordsNote = fileRecordsNewlySelected.filter(fileRecordsNote => !fileRecordsNote.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
      this.fileRecordsForUploadNote = this.fileRecordsForUploadNote.concat(validFileRecordsNote)
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    onBeforeDeleteNote(fileRecord) {
      const i = this.fileRecordsForUploadNote.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUploadNote.splice(i, 1)
        const k = this.fileRecordsNote.indexOf(fileRecord)
        if (k !== -1) this.fileRecordsNote.splice(k, 1)
      // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    fileDeletedNote(fileRecord) {
      const i = this.fileRecordsForUploadNote.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadNote.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.handle {
    width: fit-content;
    border: 1px solid #28c76f;
    padding: 0.3rem;
}
.img-wrap {
  position: relative;
}

.img-wrap img {
    border-radius: 5px;
}
.img-wrap .delete-img {
  position: absolute;
  top: -14px;
  left: 10rem;
}
.img-wrap .delete-img:hover {
  cursor: pointer;
}
.dashed {
    border: dashed;
    margin: 1rem;
    padding: 0rem;
}
</style>
