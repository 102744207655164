/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
// Notification

// eslint-disable-next-line import/prefer-default-export
export default function headersDetails() {
  const toast = useToast()
  // const editAboutPageData = ref({
  //   page_head_title: '',
  //   page_head_desc: '',
  //   page_head_image: '',
  //   page_des: '',
  //   notifidByTitle: '',
  //   notifidByImages: '',
  //   key_values_file_title: '',
  //   key_values_file_file: '',
  //   why_us_title: '',
  //   why_us_desc: '',
  //   main_key_values: [],
  //   clients: [],
  //   language_id: '',
  // })

  const code = ref('')

  watchEffect(() => store.dispatch('headersModule/getAboutPageData', { code: code.value }))

  const editAboutPageData = computed(() => store.state.headersModule.subclassifications)

  const images = computed(() => store.state.headersModule.subclassifications.clients)
  const imagesNote = computed(() => store.state.headersModule.subclassifications.notifid_by_images)

  return {
    toast,
    editAboutPageData,
    images,
    code,
    imagesNote,
  }
}
