import axios from '@axios'

export default {
  namespaced: true,
  state: {
    subclassifications: '',
  },
  getters: {},
  mutations: {
    GET_SUBCLASS(state, subclass) {
      state.subclassifications = subclass
    },
  },
  actions: {
    getAboutPageData({ commit }, { code }) {
      axios
        .get(`https://gulfdb.technocloud.live/api/get/about_us?language_code=${code}`)
        .then(response => {
          commit('GET_SUBCLASS', response.data.success)
        })
        .catch(error => console.log(error))
    },
  },
}
